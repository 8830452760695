
import { defaultChainId, NODE_RPC } from "../configs";
import Web3 from "web3";
import erc20ContractAbi from '../constant/abi/erc20.abi.json'

export const getInfoToken = async (contractAddress, chainId = defaultChainId)=>{
    try {
        const web3 = new Web3(NODE_RPC[chainId]);
        const contract = new web3.eth.Contract(erc20ContractAbi, contractAddress);
        const symbol = await contract.methods.symbol().call();
        const decimal = await contract.methods.decimals().call();
  
        return {
            symbol,
             decimal: parseInt(decimal)
        }   
    } catch (error) {
        // console.log(error);
        return {
            symbol: "",
            decimal: 18
        }
    }
   
}