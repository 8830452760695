import Web3ReactManager from "./web3/Web3ReactManager";
import { Route, Router, Switch } from "react-router-dom";
import { ROUTES } from "./constants";
import AppUpdater from "./hook/updater";
import HomePage from "./pages/home";
// import ErrorPage from "./pages/error";
import { history } from "./history";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <Router history={history}>
      <AppUpdater />
      <Web3ReactManager>
        <Switch>
          <Route
            exact
            path={ROUTES.HOMEPAGE}
            component={HomePage}
          />
          <Route
            exact
            path={ROUTES.VELAS}
            component={HomePage}
          />
          <Route
            exact
            path={ROUTES.ETH}
            component={HomePage}
          />
          <Route
            exact
            path={ROUTES.POLYGON}
            component={HomePage}
          />
          <Route
            exact
            path={ROUTES.BLAST}
            component={HomePage}
          />
          <Route
            exact
            path={ROUTES.PULES}
            component={HomePage}
          />
          <Route
            exact
            path={ROUTES.ERROR}
            component={HomePage}
          />
        </Switch>
      </Web3ReactManager>
      <ToastContainer />
    </Router>
  );
}

export default App;
