export const POOL_INTERVAL = 15000;

export const EXPLORER = {
    1: "https://etherscan.io",
    // 3: "https://ropsten.etherscan.io",
    56: "https://bscscan.com",
    97: "https://testnet.bscscan.com",
    106: 'https://evmexplorer.velas.com',
    137: 'https://polygonscan.com',
    369: "https://otter.pulsechain.com",
    81457: "https://blastscan.io" //blast testnet
};


export const NODE_RPC = {
    1: process.env.REACT_APP_NODE_INFURA_URI_1,  //eth
    // 3: "https://ropsten.infura.io/v3/f2473914890349138c8b03e3ef79d165",
    56: "https://young-necessary-layer.bsc.quiknode.pro/491437596fc64546ca9a2f1e0fe4abb423114e30/", //bsc
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    106:'https://mainnet.velas.com/rpc', //velas
    137:'https://rpc-mainnet.maticvigil.com/',  //polygon
    369: "https://rpc.pulsechain.com",  //pules chain
    81457:"https://rpc.blast.io" //blast mainnet

};


export const BLAST_CHAIN_ID = 81457;
export const POLY_CHAIN_ID = 137;
export const ETH_CHAIN_ID = 1;
export const BSC_CHAIN_ID = 56;
export const PULSE_CHAIN_ID = 369;
export const VELAS_CHAIN_ID = 106;


export const defaultChainId = 56;

export const chainSupport = [ ETH_CHAIN_ID, BSC_CHAIN_ID, VELAS_CHAIN_ID, POLY_CHAIN_ID, PULSE_CHAIN_ID, BLAST_CHAIN_ID ];


export const KYC_BACKEND_URI = 'https://bscpad.com';