import { defaultChainId, NODE_RPC } from "../configs";
import Web3 from "web3";
import vestingContractAbi from '../constant/abi/vesting2.abi.json'
import { ACTION_STATUS } from "../constants";
import { getInfoToken } from "./erc20ContractHelper";
import axios from "axios";

export const isContract = async (contractAddress, chainId) => {
  try {
    if(!contractAddress)
      return false;
    
    const web3 = new Web3(NODE_RPC[chainId]);
    const code = await web3.eth.getCode(contractAddress);
    return code !== "0x";
  } catch (error) {
      console.log("error==>", error);
    return false;
  }
};


export const getInfoVestingWallet = async (provider, contractAddress, wallet) =>{
    try {
        const web3 = new Web3(provider);
        const vestingContract = new web3.eth.Contract(vestingContractAbi, contractAddress);
        const walletInfo = await vestingContract.methods.allocation(wallet).call()
        return {
            allocated:walletInfo[0],
            unlocked:walletInfo[1],
            claimAlready:walletInfo[2],
            claimNow:walletInfo[3],
        }
      } catch (error) {
          console.log("error:", error);
         return null
      }
}

export const getInfoVestingWalletNumberOnly = async (provider, contractAddress, wallet) =>{
    try {
        const web3 = new Web3(provider);
        const vestingContract = new web3.eth.Contract(vestingContractAbi, contractAddress);
        const walletInfo = await vestingContract.methods.w(wallet).call()
        return {
            allocated:walletInfo[0],
            unlocked:walletInfo[1],
            claimed:walletInfo[2],
            claimable: walletInfo[3],
        }
      } catch (error) {
          console.log("error:", error);
         return null
      }
}
export const getInfoVestingWalletXs = async (provider, contractAddress) =>{
    try {
        const web3 = new Web3(provider);
        const vestingContract = new web3.eth.Contract(vestingContractAbi, contractAddress);
        return await vestingContract.methods.xs().call()
        
      } catch (error) {
          console.log("error:", error);
         return null
      }
}




/**
 * 
 * @param {*} contractAddress 
 * @param {*} wallet 
 * @returns 
 */
export const getMarketLabel = async (contractAddress, chainId) =>{
  
        return _useFunctionVestingContract(chainId, contractAddress, "label")
}

export const claim = async (provider, network, contractAddress, wallet, callback)=>{
    const web3 = new Web3(provider);
    const vestingContract = new web3.eth.Contract(vestingContractAbi, contractAddress);
    // const gasPrice = await getGasPrice(provider, network);
    vestingContract.methods.claim().send({from: wallet, maxPriorityFeePerGas: null, maxFeePerGas: null }).on("error", (error) => {
        console.log(error);
        callback({
          status: ACTION_STATUS.CLAIM_FAIL,
        });
      }).then((receipt) => {
        if (receipt.status == true) {
          callback({
            status: ACTION_STATUS.CLAIM_SUCCESS,
            txID: receipt.transactionHash,
          });
        } else callback({ status: ACTION_STATUS.CLAIM_FAIL });
      })
      .catch((err) => {
        console.log(err);
        callback({ status: ACTION_STATUS.CLAIM_FAIL });
      });
}


export const getInfoTokenClaims = async (chainId, contractAddress)=>{
  try {
    const tokenAddress = await _useFunctionVestingContract(chainId, contractAddress, "token");
    if(tokenAddress){
      const tokenInfo = await getInfoToken(tokenAddress, chainId )
      return tokenInfo 
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null
  }

}


export const _useFunctionVestingContract =  async(chainId, contractAddress, fun)=>{
    try {
      const web3 = new Web3(NODE_RPC[chainId]);
      const vestingContract = new web3.eth.Contract(vestingContractAbi, contractAddress);
      return await vestingContract.methods[fun]().call()
    } catch (error) {
      console.log(error);
      return null;
    }

}

export const  getGasPrice = async(provider, network)=> {
  const web3 = new Web3(provider);
  const promises = [
      (async () => {
          const gasPrice = await web3.eth.getGasPrice();
          return web3.utils.fromWei(gasPrice, 'gwei');
      })(),
  ];

  if(network==='polygon'){
    promises.push(
      (async () => {
          try {
              const response = await axios.get('https://gasstation-mainnet.matic.network/v2');
              return response.data.fast.maxFee;
          } catch (error) {
              const lastGasPrice = localStorage.getItem('lastGasPrice');
              if (lastGasPrice) {
                  return lastGasPrice;
              } else {
                
                  const gasPrice = await web3.eth.getGasPrice();
                  return Math.ceil(Math.round(this.web3.utils.fromWei(gasPrice, 'gwei') || 0)) * 2;
              }
          }
      })()
  );
  }
  

  const result = await Promise.all(promises);
  // console.log(result);
  const fasterGasPrice = Math.ceil(Math.max(...result));

  localStorage.setItem('lastGasPrice', fasterGasPrice);

  console.log(`gas price: ${fasterGasPrice} gwei`);
  return web3.utils.toWei(`${fasterGasPrice}`, 'gwei');
}