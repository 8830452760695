import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../hook/useAuth';
import { ACTION_CONST, connectors, connectorLocalStorageKey } from '../constants';
import { useActiveWeb3React } from '../hook';
import Web3Helper from '../utils/useWeb3Utils';
import { Modal, Button } from 'react-bootstrap';
import { get } from "lodash";
import { toast } from 'react-toastify';
import { switchNetWork } from '../utils/metamaskUtils';
import { BLAST_CHAIN_ID, BSC_CHAIN_ID, ETH_CHAIN_ID, POLY_CHAIN_ID, PULSE_CHAIN_ID, VELAS_CHAIN_ID } from '../configs';
import { NETWORKS_LIST } from '../network';
import { useLocation } from 'react-router-dom';
import { getNetworkFromUrl } from '../utils/utils';

const ConnectWalletModal = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const showConnectWalletModal = useSelector(state => state.wallet.showConnectWalletModal);
    const { login, logout } = useAuth();
    const { chainId } = useActiveWeb3React();

    const network = useSelector((state) => get(state, "utils.network", ''))
   
    const handleConnectClick = useCallback((walletConfig) => {
        try {
     
            login(walletConfig.connectorId);
            window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
            handleCloseModal();
            if(network){
                let switch_chainId  = NETWORKS_LIST.find((item) => item.network === network).chainId;
                if(chainId !== switch_chainId)
                    switchNetWork(switch_chainId);
                
            }else{
                const { pathname } = location;
                let network = getNetworkFromUrl(pathname);
                if(network)
                    if(chainId !== network.chainId){
                        switchNetWork(network.chainId);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [])

    useEffect(() => {
        if(!chainId || !network){
            dispatch({
                type: ACTION_CONST.LOG_OUT_WALLET_SUCCESS
            });
            return;
        }
        if (network === 'bsc' && chainId) {

            if (chainId === BSC_CHAIN_ID) {
                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_SUCCESS
                });
            } 
            
            else {
                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_FAIL
                });
                //show message connect to Binance Chain
                toast.warn('You need connect to Binance Smart Chain network!');
                // switchNetWork(BSC_CHAIN_ID)
            }

        }
        if (network == 'vlx' && chainId) {
     
            if (chainId == VELAS_CHAIN_ID) {
                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_SUCCESS
                });
            } else {

                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_FAIL
                })
                //show message connect to Velas Chain
                toast.warn('You need connect to Velas Chain network!');
                // switchNetWork(VELAS_CHAIN_ID)
            }

        }
        if (network == 'eth' && chainId) {
     
            if (chainId == ETH_CHAIN_ID) {
                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_SUCCESS
                });
            } else {

                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_FAIL
                })
                //show message connect to Ethereum Chain
                toast.warn('You need connect to Ethereum network!');
                // switchNetWork(ETH_CHAIN_ID)
            }

        }
        if (network == 'polygon' && chainId) {
     
            if (chainId == POLY_CHAIN_ID) {
                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_SUCCESS
                });
            } else {

                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_FAIL
                })
                //show message connect to Polygon Chain
                toast.warn('You need connect to Polygon network!');
                // switchNetWork(POLY_CHAIN_ID)
            }

        }
        if (network == 'pulse' && chainId) {
     
            if (chainId == PULSE_CHAIN_ID) {
                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_SUCCESS
                });
            } else {

                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_FAIL
                })
                //show message connect to Polygon Chain
                toast.warn('You need connect to Pulse Chain!');
                // switchNetWork(PULSE_CHAIN_ID)
            }

        }
        if (network == 'blast' && chainId) {
  
            if (chainId == BLAST_CHAIN_ID) {
                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_SUCCESS
                });
            } else {

                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_FAIL
                })
           
                //show message connect to Polygon Chain
                toast.warn('You need connect to Blast network!');
                // switchNetWork(BLAST_CHAIN_ID)
            }

        }
        
        
    }, [network, chainId])

    // useEffect(() => {
    //     if (error)
    //         return;
    //     if (account && library && library.provider) {

    //         const web3 = new Web3Helper(library.provider, account, chainId)
    //         dispatch({
    //             type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
    //             data: web3
    //         });
    //         handleCloseModal();
    //         return;
    //     }

    // }, [library, account, error])

const handleCloseModal = () => {
    dispatch({
        type: ACTION_CONST.HIDE_CONNECT_WALLET_MODAL
    });
}



return (
    <>
        <Modal show={showConnectWalletModal} centered={true} size="sm" onHide={handleCloseModal} id="connectWalletModal" tabIndex="-1" aria-labelledby="connectWalletModalLabel" aria-hidden="true">
            <Modal.Header closeButton>
                <Modal.Title>Connect to wallet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {connectors.map((entry, key) => {
                    if (entry.enable === true) {
                        return (
                            <Button type="button" size="lg" variant="light" key={key} onClick={() => handleConnectClick(entry)} id={`wallet-connect-${entry.title.toLocaleLowerCase()}`}
                                className="w-100 mb-2 btn-select-network"
                            >
                                <img src={entry.icon} width="30px" alt="bscpad" />
                                <div>{entry.title}</div>
                            </Button>
                        )
                    }
                })}
            </Modal.Body>
        </Modal>
    </>
);
}
export default ConnectWalletModal;


