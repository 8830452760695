import Web3 from "web3";
import { BLAST_CHAIN_ID, BSC_CHAIN_ID, EXPLORER, NODE_RPC, POLY_CHAIN_ID, PULSE_CHAIN_ID, VELAS_CHAIN_ID } from "./configs";

export const chainListSupport = 
  {
    1:{
        chainId:  Web3.utils.toHex(1), // A 0x-prefixed hexadecimal string
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
          name: 'Ethereum',
          symbol: 'ETH', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://mainnet.infura.io/v3/'],
        blockExplorerUrls: ['https://etherscan.io'], 
    },
   
    56:{
      chainId:  Web3.utils.toHex(BSC_CHAIN_ID), // A 0x-prefixed hexadecimal string
      chainName: 'Binance Smart Chain',
      nativeCurrency: {
        name: 'Binance',
        symbol: 'BNB', // 2-6 characters long
        decimals: 18,
      },
      rpcUrls: ['https://bsc-dataseed.binance.org'],
      blockExplorerUrls: ['https://bscscan.com'],
    },
    106:{
        chainId:  Web3.utils.toHex(VELAS_CHAIN_ID), // A 0x-prefixed hexadecimal string
        chainName: 'Velas Mainnet',
        nativeCurrency: {
          name: 'Velas',
          symbol: 'VLX', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://mainnet.velas.com/rpc'],
        blockExplorerUrls: [EXPLORER[VELAS_CHAIN_ID]],
      },
      369: {
        chainId:  Web3.utils.toHex(PULSE_CHAIN_ID), // A 0x-prefixed hexadecimal string
        chainName: 'PulseChain',
        nativeCurrency: {
          name: 'PulseX',
          symbol: 'PLS', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://rpc.pulsechain.com'],
        blockExplorerUrls: [EXPLORER[PULSE_CHAIN_ID]],
        },
        137: {
            chainId:  Web3.utils.numberToHex(POLY_CHAIN_ID),
            rpcUrls: ["https://rpc-mainnet.maticvigil.com"],
            chainName: "Polygon Mainnet",
            nativeCurrency: {
              name: "Matic",
              symbol: "MATIC",
              decimals: 18
            },
            blockExplorerUrls: [EXPLORER[POLY_CHAIN_ID]]
        },
        81457: {
            chainId:  Web3.utils.numberToHex(BLAST_CHAIN_ID),
            rpcUrls: [NODE_RPC[BLAST_CHAIN_ID]],
            chainName: "Blast",
            nativeCurrency: {
              name: "Ethereum",
              symbol: "ETH",
              decimals: 18
            },
            blockExplorerUrls: [EXPLORER[BLAST_CHAIN_ID]]
        },


  }

export const NETWORKS_LIST = [
    {
        chainId: 1,
        network: 'eth',
    },
    {
        chainId: 56,
        network: 'bsc',
    },
    {
        chainId: 106,
        network: 'vlx',
    },
    {
        chainId: 369,
        network: 'pulse',
    },
    {
        chainId: 137,
        network: 'polygon',
    },
    {
        chainId: 81457,
        network: 'blast',
    },
]
  