import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import useCopyToClipboard from '../shared/CopyToClibboard';
import useAuth from '../hook/useAuth';
import { useActiveWeb3React } from '../hook';
import { ACTION_CONST } from '../constants';
import { EXPLORER } from '../configs';
import { Modal } from 'react-bootstrap';

const WalletModal = () => {
    const { chainId } = useActiveWeb3React()
    const dispatch = useDispatch();
    const [copied, setCopied] = useCopyToClipboard(1000);
    const { logout } = useAuth();
    const { account } = useActiveWeb3React()
    const showWalletModal = useSelector(state => state.wallet.showWalletModal);

    const handleLogout =  () => {
        logout();
        dispatch({
            type: ACTION_CONST.LOG_OUT_WALLET_SUCCESS
        });
        handleCloseModal();
        // window.location.reload();
    }
    const handleCloseModal = () => {
        dispatch({
            type: ACTION_CONST.HIDE_WALLET_MODAL
        });
    }

    return (
        <>
            <Modal show={showWalletModal} centered={true} onHide={handleCloseModal} id="walletModal" tabIndex="-1" aria-labelledby="walletModalLabel" aria-hidden="true">
                <Modal.Header closeButton>
                    <Modal.Title>Your wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="wallet-info text-center">
                        <h5 className="wallet-address">{account}</h5>
                        <div className="d-flex align-items-center justify-content-center">
                            <a href={`${EXPLORER[chainId]}/address/${account}`} target="_blank" rel="noreferrer">
                                <span className="me-1">View on Scanner</span><i className="mdi mdi-open-in-new"></i>
                            </a>
                            <a href="#" onClick={() => setCopied(account)}>
                                <span className="me-1">Copy Address</span>{!copied ? <i className="mdi mdi-content-copy"></i> : <i className="mdi mdi-check"></i>}
                            </a>
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        <a href="#" className="btn btn-danger" onClick={handleLogout} data-bs-dismiss="modal">Logout</a>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default WalletModal;


