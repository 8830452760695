import window from 'global'
import { BigNumber } from "bignumber.js";
import { EXPLORER } from '../configs';
import { NETWORKS_LIST } from '../network';

export function isMetamaskAvailable() {
    return !!(window.ethereum && window.ethereum.isMetaMask)
}

export function isBinanceExtensionAvailable() {
    return !!window.BinanceChain
}

export function isTrustWalletAvailable() {
    return !!(window.ethereum && window.ethereum.isTrust)
}

export function retryWithTimeout(
    callback,
    callbackReject = function() {},
    retryTime = 5,
    timeout = 1000
) {
    return setTimeout(async() => {
        try {
            await callback()
        } catch (e) {
            // console.log(e.message)
            if (retryTime === 0) {
                console.error(e.message)
                await callbackReject()
            } else {
                return retryWithTimeout(callback, retryTime - 1, timeout)
            }
        }
    }, timeout)
}
export function calculateBalanceSend(balance) {
    return BigNumber(balance)
        .multipliedBy(10 ** 18)
        .integerValue()
}


export const getBscScanLink = (chainId, hash, type) => {
    if (type === "transaction") {
        return `${EXPLORER[chainId]}/tx/${hash}`
    }
    if (type === "address") {
        return `${EXPLORER[chainId]}/address/${hash}`
    }

}

export const getNetworkFromUrl = (pathname) => {

    if (pathname.indexOf('velas') !== -1) {
        return NETWORKS_LIST.find((item) => item.network === 'vlx');
      
    } else if(pathname.indexOf('eth') !== -1){
        return NETWORKS_LIST.find((item) => item.network === 'eth'); 
    }
    else if(pathname.indexOf('polygon') !== -1){
        return NETWORKS_LIST.find((item) => item.network === 'polygon'); 
    }
    else if(pathname.indexOf('blast') !== -1){
        return NETWORKS_LIST.find((item) => item.network === 'blast'); 
     
    }
    else if(pathname.indexOf('pulse') !== -1){

        return NETWORKS_LIST.find((item) => item.network === 'pulse'); 
    }
    else {
        return NETWORKS_LIST.find((item) => item.network === 'bsc'); 
    }

}