import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_CONST, ACTION_STATUS, MESSAGE, ROUTES } from "../constants";
import { useActiveWeb3React } from "../hook";
import ConnectWalletModal from "../modals/ConnectWalletModal";
import WalletModal from "../modals/WalletModal";
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useParams, useLocation } from "react-router-dom";
import { formatNumberNumberWithDecimals, helpers } from "../utils/helpers";
import { claim, getInfoTokenClaims, getInfoVestingWallet, getInfoVestingWalletNumberOnly, getInfoVestingWalletXs, getMarketLabel, isContract } from "../utils/vestingHelper";
import TransactionConfirmationModal from '../modals/TransactionConfirmationModal/TransactionConfirmationModal';
import TransactionErrorContent from '../modals/TransactionConfirmationModal/TransactionErrorContent';
import { useErrorModal, useLatestBlockNumber, useMessage, useSuccessModal, useTransactionHash, useWaitingModal } from "../hook/useState";
import { get } from "lodash";
import {version} from '../../package.json'
import {  BLAST_CHAIN_ID, BSC_CHAIN_ID, ETH_CHAIN_ID, POLY_CHAIN_ID, PULSE_CHAIN_ID, VELAS_CHAIN_ID } from "../configs";


const HomePage = () => {

    const dispatch = useDispatch();
    const [contractAddress, setContractAddress] = useState("");
    const [walletData, setWalletData] = useState([0, 0, 0, 0]);
    const [showError, setShowError] = useState(false);
    const [marketLabel, setMarketLabel] = useState("");
    const [enableClaim, setEnableClaim] = useState(false);
    const [releaseTitle, setReleaseTitle] = useState([]);
    const [symbol, setSymbol] = useState("");
    const [decimals, setDecimals] = useState(18);
    const [messageConnect, setMessageConnect] = useState("")

    // const [network, setNetwork] = useState('bsc');
    const network = useSelector((state) => get(state, "utils.network", ''))



    const blockNumber = useLatestBlockNumber();
    const openWaitingModal = useWaitingModal()
    const openErrorModal = useErrorModal()
    const openSuccessModal = useSuccessModal();
    const message = useMessage();
    const textHash = useTransactionHash();

    const { account, library } = useActiveWeb3React();
    // let params = useParams();

    const location = useLocation();

    const handleOpenConnectWalletModal = () => {
        dispatch({
            type: ACTION_CONST.SHOW_CONNECT_WALLET_MODAL
        });
    }
    const handleOpenWalletModal = () => {
        dispatch({
            type: ACTION_CONST.SHOW_WALLET_MODAL
        });

    }

    //check is contract Address
    const isContractAddress = useCallback(async (address, chain) => {
            const check = await isContract(address, chain);
            if (check) {
                setContractAddress(address)
            } else {
                setShowError(true);
            }

    }, [network]);



    const connectedWallet = useSelector((state) => get(state, "utils.connectedWallet", false))

    // check params
    useEffect(() => {
        const { pathname } = location;
  
        let contract = pathname.replaceAll(`/`, "");

        if (pathname.indexOf('velas') !== -1) {
            // setNetwork('vlx');
            dispatch({
                type: ACTION_CONST.NETWORK_CONNECTOR,
                data: 'vlx'
            })
            contract = contract.replace(`velas`, "").trim();
            isContractAddress(contract, VELAS_CHAIN_ID)
        } else if(pathname.indexOf('eth') !== -1){
            
            dispatch({
                type: ACTION_CONST.NETWORK_CONNECTOR,
                data: 'eth'
            })
            contract = contract.replace(`eth`, "").trim();
            isContractAddress(contract, ETH_CHAIN_ID)
        }
        else if(pathname.indexOf('polygon') !== -1){
            
            dispatch({
                type: ACTION_CONST.NETWORK_CONNECTOR,
                data: 'polygon'
            })
            contract = contract.replace(`polygon`, "").trim();
            isContractAddress(contract, POLY_CHAIN_ID)
        }
        else if(pathname.indexOf('blast') !== -1){

            dispatch({
                type: ACTION_CONST.NETWORK_CONNECTOR,
                data: 'blast'
            })

            contract = contract.replace(`blast`, "").trim();
            isContractAddress(contract, BLAST_CHAIN_ID)
        }
        else if(pathname.indexOf('pulse') !== -1){
    
            dispatch({
                type: ACTION_CONST.NETWORK_CONNECTOR,
                data: 'pulse'
            })
            
            contract = contract.replace(`pulse`, "").trim();
            isContractAddress(contract, PULSE_CHAIN_ID)
        }
        else {
            isContractAddress(contract, BSC_CHAIN_ID);
            dispatch({
                type: ACTION_CONST.NETWORK_CONNECTOR,
                data: 'bsc'
            })
        }

    }, [location]);

    useEffect(() => {
        if (contractAddress && network) {
            let chain = 56;
            switch (network) {
                case "bsc":
                    chain = BSC_CHAIN_ID; 
                    break;
                case "vlx":
                    chain = VELAS_CHAIN_ID; 
                    break;
                case "eth":
                    chain = ETH_CHAIN_ID; 
                    break;
                case "polygon":
                    chain = POLY_CHAIN_ID; 
                case "pulse":
                    chain = PULSE_CHAIN_ID; 
                    break;
                case "blast":
                    chain = BLAST_CHAIN_ID; 
                    break;
                default:
                    break;
            }

            if(chain){
                getMarketLabel(contractAddress, chain).then(label => {
                    setMarketLabel(label)
                })
                getInfoTokenClaims(chain, contractAddress).then(token => {
                    if(token){
                        setSymbol(token.symbol);
                        setDecimals(token.decimal)
                    }
                })
            }
           
        }

    }, [contractAddress, network])

    useEffect(() => {
      
        if (account && contractAddress && library && connectedWallet) {
         
            getInfoVestingWalletXs(library.provider, contractAddress).then(r => {
                setReleaseTitle(r)
            })

            getInfoVestingWalletNumberOnly(library.provider, contractAddress, account).then(data => {
                if (data) {
                    setWalletData([
                        data.allocated,
                        data.unlocked,
                        data.claimed,
                        data.claimable,
                    ])
                    if (data.claimable > 0) {
                        setEnableClaim(true)
                    }
                }

            })
        }
    }, [contractAddress, account, library, blockNumber, connectedWallet]);

    const handleClaim = () => {
        if (library) {
            dispatch({ type: ACTION_CONST.REQUEST_SUBMIT });
            claim(library.provider, network, contractAddress, account, (result) => {
                if (result.status === ACTION_STATUS.CLAIM_SUCCESS) {
                    dispatch({ type: ACTION_CONST.REQUEST_SUCCESS })
                    dispatch({ type: ACTION_CONST.SET_SHOW_TRANSACTION_HASH_MODAL, data: result.txID });
                }
                if (result.status === ACTION_STATUS.CLAIM_FAIL) {
                    dispatch({ type: ACTION_CONST.REQUEST_FAIL })
                    dispatch({ type: ACTION_CONST.SET_MESSAGE, data: MESSAGE.CLAIM_FAILS });
                }
            })
        }
    }



    const clearState = () => {
        dispatch({ type: ACTION_CONST.REQUEST_DONE })
        dispatch({ type: ACTION_CONST.SET_MESSAGE, data: "" });
        dispatch({ type: ACTION_CONST.SET_SHOW_TRANSACTION_HASH_MODAL, data: "" });
    }

    //check show message connect 
    useEffect(()=>{
        switch (network) {
            case "bsc":
                setMessageConnect("You need connect wallet on Binance Smart Chain to claim.")
                break;
            case "vlx":
                setMessageConnect("You need connect wallet on Velas Chain to claim.")
                break;
            case "eth":
                setMessageConnect("You need connect wallet on Ethereum network to claim.")
                break;
            case "polygon":
                setMessageConnect("You need connect wallet on Polygon network to claim.")
                break;
            default:
                break;
        }
    },[network])

    const CardComponent = ({ label, value }) => {
        return <div className="rd-card">
            <div className="rd-card-body">
                <div className="rd-card-label">{label}</div>
                <div className="rd-card-value">{value}</div>
            </div>
        </div>
    }

    console.log("version:", version);

    return (
        <>
            <TransactionConfirmationModal isOpen={openWaitingModal} attemptingTxn={true} pendingText="" />

            <TransactionConfirmationModal isOpen={openSuccessModal} onDismiss={() => clearState()} hash={textHash} message={message} />


            <TransactionConfirmationModal isOpen={openErrorModal} onDismiss={() => clearState()}>
                <TransactionErrorContent message={message} onDismiss={() => clearState()} />
            </TransactionConfirmationModal>
            <header>
                <Container>
                    <div className={`py-3 d-flex flex-wrap align-items-center ${!account ? 'justify-content-md-between justify-content-center' : 'justify-content-between'}`}>
                        <a href="#" target="_blank">
                            <img className="d-none d-md-block" src="/images/logo.png" height="50" alt="Logo" />
                            <img className="d-md-none d-block" src="/images/logo-sm.png" height="50" alt="Logo" />
                        </a>
                        {connectedWallet ? <button type="button" className="btn btn-primary" onClick={handleOpenWalletModal}>
                            <span>{helpers.formatTransactionHash(account, 5, 5)}</span>
                        </button> : <button type="button" className="btn btn-primary" onClick={handleOpenConnectWalletModal}>
                            Connect wallet
                        </button>
                        }
                    </div>
                </Container>
            </header>
            <main>
                <Container>
                    {contractAddress &&
                        <Row className="justify-content-center mb-3">
                            <Col xl="6" lg="10">
                                <div className="rd-card rd-card-error mb-0 mb-md-2">
                                    <div className="rd-card-body py-2">
                                        {marketLabel}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    }

                    {showError ? <Row className="justify-content-center">
                        <Col xl="6" lg="10">
                            <div className="rd-card rd-card-error">
                                <div className="rd-card-body p-5">
                                    <div className="rd-card-icon">
                                        <i className="fas fa-exclamation-triangle"></i>
                                    </div>
                                    <div className="rd-card-notice mb-4">
                                        Page does not exist
                                    </div>
                                    {/* <button type="button" className="btn btn-primary" onClick={handleOpenConnectWalletModal}>
                                        Back
                                    </button> */}
                                </div>
                            </div>
                        </Col>
                    </Row> : <>
                        {!connectedWallet ? <Row className="justify-content-center">
                            <Col xl="6" lg="10">
                                <div className="rd-card rd-card-warning">
                                    <div className="rd-card-body p-5">
                                        <div className="rd-card-icon">
                                            <i className="fas fa-exclamation-triangle"></i>
                                        </div>
                                        <div className="rd-card-notice mb-4">
                                            {
                                                messageConnect
                                            }
                                        
                                        </div>
                                        <button type="button" className="btn btn-primary" onClick={handleOpenConnectWalletModal}>
                                            Connect wallet
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row> :
                            <>
                                {<div className="rd-card rd-card-release mt-4">
                                    <div className="rd-card-body">
                                        <h5 className="mb-4">Releases</h5>

                                        {releaseTitle &&
                                            releaseTitle.map((item, key) =>
                                                <p key={key}>{item}</p>
                                            )

                                        }

                                    </div>
                                </div>}


                                <Row className="gx-lg-5 mb-5 pt-3">
                                    <Col lg="3" xs="6">
                                        <CardComponent label="Allocated" value={`${formatNumberNumberWithDecimals(walletData[0], decimals, 4)} ${symbol}`} />
                                    </Col>
                                    <Col lg="3" xs="6">
                                        <CardComponent label="Unlocked Now" value={`${formatNumberNumberWithDecimals(walletData[1], decimals, 4)} ${symbol}`} />
                                    </Col>
                                    <Col lg="3" xs="6">
                                        <CardComponent label="Claimed already" value={`${formatNumberNumberWithDecimals(walletData[2], decimals, 4)} ${symbol}`} />
                                    </Col>
                                    <Col lg="3" xs="6">
                                        <CardComponent label="Claimable Now" value={`${formatNumberNumberWithDecimals(walletData[3], decimals, 4)} ${symbol}`} />
                                    </Col>
                                </Row>



                                <ul className="lav-nav">
                                    <li>
                                        <button className='btn-lav' onClick={handleClaim} disabled={!enableClaim}>
                                            <div className="btn-wrap">
                                                <span>Claim</span>
                                            </div>
                                            <div className="ripple"></div>
                                        </button>
                                    </li>
                                </ul>
                            </>
                        }
                    </>
                    }
                </Container>
            </main>
            <ConnectWalletModal />
            <WalletModal />
        </>
    );
};

export default HomePage;
