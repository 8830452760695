import { useSelector } from "react-redux"
import { get } from "lodash";
import { STATE } from "../constants";


export const useLatestBlockNumber = () => {
    return useSelector((state) => get(state, "utils.latestBlock", 0))
}

export const useChainIdNetwork = () => {
    return useSelector(state => get(state, "utils.chainId", null))
}

export const useWeb3Utils = () => {
    return useSelector((state) => get(state, "utils.web3Utils", null));
}


export const useEthBalance = () => {
    return useSelector((state) => get(state, "wallet.ethBalance", 0));
}



export const useWaitingModal = () => {
    const state = useSelector((state) =>get(state, "utils.statusRequest", 'none'));
    return state === STATE.SUBMIT
}

export const useErrorModal = () =>{
    const state = useSelector((state) =>get(state, "utils.statusRequest", 'none'));
    return state === STATE.ERROR
}

export const useSuccessModal = () =>{
    const state = useSelector((state) =>get(state, "utils.statusRequest", 'none'));
    return state === STATE.SUCCESS
}

export const useMessage=()=>{
    return useSelector((state) =>get(state, "utils.message", ''));
}

export const useTransactionHash = ()=>{
    return useSelector((state) =>get(state, "utils.transactionHash", ''));
}

export const useNativeCoin = (chainId) =>{
    if(chainId == 3 || chainId == 1  ){
        return "ETH"
      }
      if(chainId ==56 || chainId ==97  ){
        return "BNB"
      }
      return "ETH"
}

export const useNetWork = (chainId)=>{
    if(chainId == 3 || chainId == 1  ){
        return "Ethereum Network"
      }
      if(chainId ==56 || chainId ==97  ){
        return "Binance Smart Chain Network"
      }
      return "Ethereum Network"
}

export const useKycStatus = () =>{
    return   useSelector((state) => get(state, "wallet.kycStatus", null));
}

export const useStakeAmount = () =>{
    return   useSelector((state) => get(state, "wallet.stakingWalletInfo.stakedAmount", 0));
} 
